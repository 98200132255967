import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Link, Grid } from '@mui/material'
import { TextComponent } from '@front/squirtle'

const CustomLink = ({ label, href, value }) =>
  <Grid item xs>
    <Typography sx={{ fontSize: 13 }}> {label} </Typography>
    <Typography>
      {!!value ?
        <Link href={href} target={'_blank'}>
          {value}
        </Link>
        : '-'
      }
    </Typography>
  </Grid>

const Row1 = ({ driver, license }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={3}>
      <CustomLink
        label={I18n.t('driver.label', { count: 1 })}
        href={`/driver/${_.get(driver, 'user_id')}`}
        value={_.get(driver, 'client_id')}
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('name.label.full')}
        value={_.get(driver, 'name')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs={3}>
      <CustomLink
        label={I18n.t('contract.id')}
        href={`/adherent/${_.get(license, 'group_id')}/license/${_.get(license, 'license_id')}`}
        value={_.get(license, 'client_id')}
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('group.status')}
        value={_.get(license, 'billing_category')}
        disabled
        emptyValue='-'
      />
    </Grid>
  </Grid>

const Row2 = ({ vehicle, license }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <CustomLink
        label={I18n.t('vehicle.label', { count: 1 })}
        href={
          !!_.get(license, 'group_id')
            ? `/adherent/${_.get(vehicle, 'group_id')}/vehicle/${_.get(vehicle, 'vehicle_id')}`
            : `/vehicle/${_.get(vehicle, 'vehicle_id')}`
        }
        value={_.get(vehicle, 'plaque')}
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('vehicle.brand', { count: 1 })}
        value={_.get(vehicle, 'brand')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('vehicle.model', { count: 1 })}
        value={_.get(vehicle, 'model')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs />
  </Grid>

const Row3 = ({ rating }) =>
  <Grid item xs={12}>
    <TextComponent
      label={I18n.t('comment.order_client')}
      value={_.get(rating, 'comment')}
      disabled
      emptyValue='-'
      multiline
    />
  </Grid>

const Row4 = ({ driver }) =>
  <Grid item xs={12}>
    <TextComponent
      label={I18n.t('comment.payment_driver')}
      value={_.get(driver, 'comment')}
      disabled
      emptyValue='-'
      multiline
    />
  </Grid>

const DriverInfoSection = props =>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
    <Typography variant='h5'>{I18n.t('transport.label.alter')}</Typography>
    <Box sx={{ p: 1 }}>
      <Grid container rowSpacing={1.5}>
        <Row1 {...props} />
        <Row2 {...props} />
        <Row3 {...props} />
        <Row4 {...props} />
      </Grid>
    </Box>
  </Box>

export default React.memo(DriverInfoSection)
