import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'
import _ from 'lodash'

import { HookUtils } from '@front/volcanion/utils'

function getDisabledTypes(estimations, selectedCommercialPackage, estimation_type_dirty, estimation_type) {
  const matched_cps = _.filter(estimations, ['commercial_package_id', selectedCommercialPackage])
  const cp_estimation_types = _.uniq(_.compact(_.map(matched_cps, 'type')))
  const final_cp_types = _.uniq(_.compact(_.flatten([!estimation_type_dirty && _.isEmpty(cp_estimation_types) ? [estimation_type] : [], cp_estimation_types])))
  return _.compact(_.difference(['fixed', 'estimated'], final_cp_types))
}
function getCPIds(packages, selectedCommercialPackage, isReadOnly) {
  if (!!isReadOnly || (!!selectedCommercialPackage && _.isEmpty(packages)))
    return [selectedCommercialPackage]
  return _.compact(_.uniq(_.map(packages, 'commercial_package_id')))
}
const withContainer = Component => props => {
  const [{ dirty: estimation_type_dirty } = {}] = hooks.useFieldMeta('estimation_type')
  const [
    selectedCommercialPackage,
    estimation_type,
    companyservice_id,
    mode,
    duration,
    requestedAt,
    source_details,
    destination_details,
    application,
    commercial_formula,
    load_type,
    load_count,
    vehicle_opts,
    driver_opts,
    returnAt,
  ] = hooks.useFieldValues([
    'commercial_package',
    'estimation_type',
    'user.companyservice',
    'ridemode',
    'ridemode_duration',
    'requestedAt',
    'source',
    'destination',
    'user.application',
    'user.commercial_formula',
    'load_type',
    'load_count',
    'vehicle_opts',
    'driver_opts',
    'returnAt',
  ])

  const setFormValues = hooks.useFormChange()
  const isReady = hooks.useFormStatus('isReady')
  const isReadOnly = hooks.useFormStateValue('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const { user_id } = hooks.useFormStateValue()

  const [estimations, packages, status, { refreshEstimate }] = hooks.useEstimation({
    service: companyservice_id,
    user_id,
    requestedAt,
    source_details,
    destination_details,
    mode,
    duration,
    vehicle_opts,
    driver_opts,
    returnAt,
    load_type,
    load_count,
    application,
    commercial_formula,
  }, { isReady, disabled: isReadOnly, disable_destination_validate: true })
  const cp_ids = getCPIds(packages, selectedCommercialPackage, isReadOnly)

  const { isEstimationLoading, isEstimationFetched } = status

  const disabled_types = getDisabledTypes(estimations, selectedCommercialPackage, estimation_type_dirty, estimation_type)
  const estimation_ready = _.isEmpty(estimations) || disabled_types.length === 3 || !!HookUtils.getLoadingState([status])
  const hideEstimationType = !!isManagingRecord ? (!!isEstimationFetched && !!estimation_ready) : !!estimation_ready
  const hideRefreshEstimate = !!isManagingRecord ? !!isEstimationLoading || !!isReadOnly : !!estimation_ready

  useEffect(() => {
    if (!isReadOnly && !isEstimationLoading && !!estimation_type) {
      setFormValues([{ field: 'estimation_type', value: undefined }])
    }
  }, [isEstimationLoading])

  const mergedProps = {
    mode,
    cp_ids,
    isLoading: status?.isAuthorizedLoading,
    hideEstimationType,
    disabled_types,
    refreshEstimate,
    hideRefreshEstimate,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
