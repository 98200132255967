import React from 'react'

import { CDNImage } from '@front/volcanion'

const PackageIcons = (props) => {
  const { option } = props
  return (
    <CDNImage name={_.get(option, 'client_type_icon')} category='packages' style={{ padding: 20, paddingBottom: 0, paddingTop: 16, maxHeight: 100 }} />
  )
}

export default React.memo(PackageIcons)