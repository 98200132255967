import React from 'react'
import moment from 'moment'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row, isSelected, ...props }) => {
  const [platform_os, platform_version, platform_name] = _.split(_.get(row, 'platform'), '/')
  return <>
    <TableCell size={'small'}> <TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'))} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={moment.utc(_.get(row, 'lastSeenAt')).calendar()} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={`${platform_os} (${platform_version})`} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={platform_name} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={_.get(row, 'version')} /> </TableCell>
  </>;
}

export default React.memo(Row)
