import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const license_equipment_id = _.get(row, 'license_equipment_id')
  const [deleteRecord] = hooks.useModelFunction('licenseequipment', 'delete')
  const onDelete = useCallback(() => deleteRecord({ license_equipment_id }), [license_equipment_id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
