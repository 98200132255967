import React from 'react'
import { I18n } from '@front/volcanion'
import { OptionUtils } from '@front/volcanion/utils'

import { Typography, StepLabel, StepContent, Grid } from '@mui/material'
import { TextField, TextComponent, ModelMenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'

const OptionsSection = ({
  vehicle_opts,
  driver_opts,
  isLoading,
  meeting_point,
  driver_instruction,
  isManagingRecord,
  isB2B,
  service_comment
}) => <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('options.label.alter')}</Typography>
    </StepLabel>
    <StepContent>
      <Grid container spacing={1} rowSpacing={1.5}>
        <Grid item xs={12}>
          <SingleOptionSelectorField
            name={'exclude_heatmap'}
            label={I18n.t('order.exclude_heatmap')}
            clearOnEmpty={false}
          />
        </Grid>
        <Grid item xs={12} hidden={!isB2B}>
          <TextComponent
            label={I18n.t('comment.b2b')}
            value={service_comment}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'driver_comment'}
            label={I18n.t('info.driver')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextComponent
            name={'info.driver_instruction'}
            label={I18n.t('driver.instruction')}
            value={driver_instruction}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextComponent
            name={'info.meeting_point'}
            label={I18n.t('address.meeting_point')}
            value={meeting_point}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ModelMenuSelectorField
            name={'driver_opts'}
            label={I18n.t('options.useroption.label', { count: 1 })}
            model_name={'useroption'}
            config={{
              ids: driver_opts,
              mutator: elem => ({ label: OptionUtils.getLabel(elem, !isManagingRecord), ...elem })
            }}
            multiple
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <ModelMenuSelectorField
            name={'vehicle_opts'}
            label={I18n.t('options.vehicleoption.label', { count: 1 })}
            model_name={'vehicleoption'}
            config={{
              ids: vehicle_opts,
              mutator: elem => ({ label: OptionUtils.getLabel(elem, !isManagingRecord), ...elem })
            }}
            multiple
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </StepContent>
  </>

export default React.memo(OptionsSection)
