import React from 'react'

import { I18n } from '@front/volcanion/'
import { Box, StepLabel, StepContent, Typography } from '@mui/material'

import TranslationPanel from '@abra/panels/Translation'

const TranslationSection = ({ isManagingRecord, model_name, title, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('translation', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, pl: 0, boxShadow: 6, borderRadius: 3 }}>
      <TranslationPanel
        labelKey={!isManagingRecord
          ? `options.${model_name}.create`
          : `options.${model_name}.detail`
        }
        titleParams={{ title }}
        {...props}
      />
    </Box>
  </StepContent>
</>


export default React.memo(TranslationSection)
