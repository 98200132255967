
import React from 'react'

import { Button, Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'
import { I18n } from '@front/volcanion'

import FormHeader, { Actions } from '@abra/elements/headers/Form'
import GroupTab from '@abra/panels/Tab/Group'

import PrimarySection from './Section/Primary'
import OptionsSection from './Section/Equipment'

import Popup from './Popup'

const License = ({ isManagingRecord, openDialog, hasRelayVehicle }) =>
  <Grid container item justifyContent='center' xs={9} rowSpacing={3}>
    <Grid item xs={12}>
      <GroupTab value={2} />
    </Grid>
    <Grid item xs={12} >
      <Popup />
      <FormHeader >
        <Actions>
          {!hasRelayVehicle ? (
            <Button variant='contained' onClick={() => openDialog('relay_popup')} >
              {I18n.t('contract.add_relay')}
            </Button>
          ) : (
            <Button variant='contained' onClick={() => openDialog('relay_popup')} >
              {I18n.t('contract.remove_relay')}
            </Button>
          )}
        </Actions>
      </FormHeader>
      <GridStepper groupsCount={[2]} activeStep={0} orientation="vertical" getStepProps={index => ({ active: index !== 1 || !!isManagingRecord })} >
        <Step> <PrimarySection />  </Step>
        <Step> <OptionsSection /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(License)
