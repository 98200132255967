import React from 'react'

import { Typography, Chip } from '@mui/material'
import { Loader } from '@front/squirtle'

const PackagePrices = ({ rate, isLoading, option, selectorProps }) =>
  <Loader isLoading={isLoading} nativeLoader type="linear" _container={{ borderRadius: 3 }} _loader={{ color: 'secondary' }}>
    <Chip
      size='small'
      label={<Typography textAlign='center' fontSize={18} sx={{
        color: selectorProps.isOptionSelected(option) ? 'extraWhite' : 'primary.main',
      }}>{rate}</Typography>
      }
      sx={{ boxShadow: 1, p: 1 }}
    />
  </Loader>

export default React.memo(PackagePrices)