import React, { useCallback, useState, useEffect } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'

import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ disabled, ...props }) => {
  const isMounted = hooks.useFormStatus('isMounted')
  const [eta, setEta] = useState(null)
  const [estimatedDelay, setEstimatedDelay] = useState(null)
  const [traffic_margin] = hooks.useFormState('margin')

  const [record_id] = hooks.useFormState('record_id')
  const [user_id] = hooks.useFormState('user_id')
  const [
    driver_opts,
    vehicle_opts,
    source_details,
    source_formatted_address,
    commercial_package,
    schedule_type,
    requestedAt,
    special_price,
    estimation_type,
    load_count,
    has_luggage,
  ] = hooks.useFieldValues([
    'driver_opts',
    'vehicle_opts',
    'source',
    'source.formatted_address',
    'commercial_package',
    'schedule_type',
    'requestedAt',
    'special_price',
    'estimation_type',
    'load_count',
    'has_luggage',
  ])
  const [availableEstimation] = hooks.useSelectedEstimation(commercial_package)
  const [selectedEstimation] = hooks.useSelectedEstimation(commercial_package, estimation_type)

  const [order] = hooks.useModel('order', [record_id], { populate: ['quotation', 'info', 'driver_estimation'], single: true })
  const getCustomProps = useCallback(Callbacks.getCustomPropsHandler(selectedEstimation, order, requestedAt, schedule_type, eta, traffic_margin), [selectedEstimation, order, requestedAt, schedule_type, eta, traffic_margin])
  const [getDetails] = hooks.useModelFunction('address', 'getDetails')
  const refreshETA = useCallback(Callbacks.refreshETAHandler(getDetails, setEta, setEstimatedDelay), [getDetails, setEta, setEstimatedDelay])

  useEffect(() => {
    if (!isMounted || !commercial_package || schedule_type !== 'immediate') return
    refreshETA(driver_opts, vehicle_opts, source_details, user_id, commercial_package, load_count, has_luggage)
  }, [commercial_package, source_formatted_address, user_id, load_count, has_luggage])

  const margin = TimeUtils.secondsToTime((traffic_margin || _.get(order, 'info.margin')) * 60, null, { hide_seconds: true })

  const mergedProps = {
    schedule_type,
    margin,
    disabled,
    estimation_type,
    isManualPriceRequired: estimation_type === 'special' && availableEstimation?.type !== 'special',
    special_price,
    eta,
    estimatedDelay,
    ...getCustomProps()
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
