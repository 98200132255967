import React from 'react'
import { hooks } from '@front/volcanion'

import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [ridemode_id, ridemode_duration] = hooks.useFieldValues(['ridemode', 'ridemode_duration'])

  const options = _.orderBy(_.flatten([
    hooks.useRidemodeDurationOptions(ridemode_id),
    _.compact([!!isManagingRecord && { label: TimeUtils.secondsToTime(ridemode_duration, 'HH[h]mm[min]'), value: ridemode_duration }])
  ]), ['value'], ['asc'])

  const mergedProps = {
    options
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
