import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, StepLabel, StepContent } from '@mui/material'
import { MenuSelectorField } from '@front/squirtle'

const RidemodeDurationSection = ({ options, ...props }) => <>
  <StepLabel><Typography variant='h5'>{I18n.t('ride.duration.label')}</Typography></StepLabel>
  <StepContent>
    <Grid container>
      <Grid item xs={4}>
        <MenuSelectorField
          label={I18n.t('ride.duration.time')}
          name={'ridemode_duration'}
          options={options}
          required
        />
      </Grid>
    </Grid>

  </StepContent>
</>

export default React.memo(RidemodeDurationSection)
