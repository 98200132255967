
class Callbacks {
  static getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount) {
    return function getTotal() {
      const total = _.compact([
        _.toNumber(handling),
        _.toNumber(base),
        _.toNumber(driver_package_fee),
        _.toNumber(fees),
        _.toNumber(prepaid),
        _.toNumber(duration_amount),
        _.toNumber(option_amount)
      ])
      return _.round(_.sum(total), 2).toFixed(2)
    }
  }

  static updateBilledHandlingDurationHandler(value) {
    return function updateBilledHandlingDuration(setFormValues, handling_amount, handling_slice) {
      const updates = _.compact(_.flatten([
        { field: 'assigned_transport.info.billed_handling_duration', value: handling_amount },
        { field: 'handling', value: handling_slice }
      ]))
      setFormValues(updates)
    }
  }

}

export default Callbacks
