import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const [{ record_id, ridemode_validation }, setFormState] = hooks.useFormState()

  const new_ridemode_id = ridemode_validation?.context?.data?.ridemode
  const setFormValues = hooks.useFormChange()

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['assigned_transport.driver.info'] })

  const [createComment] = hooks.useModelFunction('comment', 'generate')
  const [dispatchJob] = hooks.useModelFunction('job', 'dispatch')
  const [sendDriverMessage] = hooks.useModelFunction('broadcast', 'generate')

  const job_id = _.get(order, 'job')
  const driver_id = _.get(order, 'assigned_transport.driver.user_id')

  const onCloseScheduleInformation = () => setFormState({ unauthorized_schedule: false })
  const onCloseTrafficAlertInformation = () => setFormState({ traffic_alert: false })

  const handleSendComment = useCallback(Callbacks.handleSendCommentHandler(createComment, record_id, openNotification), [createComment, record_id, openNotification])
  const handleManualDispatch = useCallback(Callbacks.handleManualDispatchHandler(dispatchJob, record_id, job_id, openNotification), [dispatchJob, record_id, job_id, openNotification])
  const handleBeforeManualDispatch = useCallback(Callbacks.handleBeforeManualDispatchHandler(openNotification), [openNotification])
  const handleSendMessageDriver = useCallback(Callbacks.handleSendMessageDriverHandler(sendDriverMessage, driver_id, openNotification), [sendDriverMessage, driver_id, openNotification])
  const handleRedispatch = useCallback(Callbacks.handleRedispatchHandler(dispatchJob, job_id, record_id, openNotification), [dispatchJob, job_id, record_id, openNotification])

  const onCloseInformationValidation = useCallback(() => {
    setFormValues([{ field: 'ridemode', value: new_ridemode_id }, { field: 'returnAt', value: undefined }])
  }, [setFormValues, new_ridemode_id])

  const mergedProps = {
    record_id,
    onCloseInformationValidation,
    onCloseScheduleInformation,
    onCloseTrafficAlertInformation,
    handleSendComment,
    handleManualDispatch,
    handleBeforeManualDispatch,
    handleSendMessageDriver,
    handleRedispatch,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
