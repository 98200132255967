import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [unlockUser] = hooks.useModelFunction('device', 'delete')
  const [logoutUser] = hooks.useModelFunction('refreshtoken', 'delete')
  const navigate = hooks.useNavigate()
  const { openNotification } = hooks.useNotification()

  const mergedProps = {
    isManagingRecord,
    navigate,
    record_id,
    unlockUser,
    logoutUser,
    openNotification
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
