import moment from 'moment'

class Memos {
  static handleCanOrangeWait(schedule_type, createdAt, call_id, EXTERNAL_PHONE_SERVICE) {
    return EXTERNAL_PHONE_SERVICE === 'orange'
      && schedule_type === 'immediate'
      && !!call_id
      && moment.utc(createdAt).isAfter(moment.utc().subtract(60, 'minutes'))
  }
}

export default Memos
