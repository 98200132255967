import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [model_name] = hooks.useFormState('model_name')
  const goBackPath =
    model_name === 'useroption' && '/driver-option' ||
    model_name === 'vehicleoption' && '/vehicle-option'

  const mergedProps = {
    model_name,
    goBackPath,
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
