class Callbacks {
  static handleClickClientDetailHandler(user_id) {
    return function handleClickClientDetail() {
      if (!user_id) return
      window.open(`/user/${user_id}`, '_blank')
    }
  }
  static handleClickClientHistoryHandler(gsm) {
    return function handleClickClientHistory() {
      if (!gsm) return
      window.open(`/order?client_phone=${_.replace(gsm, '+', '')}`, '_blank')
    }
  }
  static handleClickOrderJobHandler(job_id) {
    return function handleClickOrderJob() {
      if (!job_id) return
      window.open(`/job/${job_id}`, '_blank')
    }
  }
  static handleClickCollectoMapHandler() {
    return function handleClickCollectoMap() {
      window.open(
        'https://www.google.com/maps/d/u/0/viewer?mid=1D2OvXVUSmFb6s6ZbR7syp3R2MRFNBBRr&ll=50.84458613986322%2C4.3446625635253895&z=12',
        '_blank'
      )
    }
  }
}

export default Callbacks
