import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import FrameCallbacks from '../../callbacks'
import { TimeUtils } from '@front/volcanion/utils'
import moment from 'moment'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const setFormValues = hooks.useFormChange()
  const [{ record_id, isDurationRideMode, displayDownloadBillingButton, psp }] = hooks.useFormState()
  const [
    load_type,
    handling,
    base,
    driver_package_fee,
    prepaid,
    fees,
    duration_amount,
    option_amount,
    billing_status,
    effective_handling_duration,
  ] = hooks.useFieldValues([
    'load_type',
    'handling',
    'base',
    'driver_package_fee',
    'prepaid',
    'fees',
    'duration_amount',
    'option_amount',
    'billing_status',
    'assigned_transport.info.effective_handling_duration'
  ])

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['specific_parameter', 'quotation', 'assigned_transport.info'] })
  const billing_color = hooks.useBillingStatusColorList()[billing_status]
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const handling_amount = hooks.useOrderHandlingAmount(record_id, effective_handling_duration)

  const handling_slice = order?.quotation?.rate_parameter?.handling_slice || 0
  const stripeLink = !!psp ? `https://dashboard.stripe.com/payments/${psp}` : null
  const duration = TimeUtils.secondsToTime(moment(order?.assigned_transport?.info?.departureDropoffAt).diff(moment(order?.assigned_transport?.info?.departurePickedAt), 'seconds'), null, { hide_seconds: true })
  const formated_effective_handling_duration = TimeUtils.secondsToTime(effective_handling_duration * 60, null, { hide_seconds: true })

  const total = useMemo(Callbacks.getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount), [handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount])
  const updateBilledHandlingDuration = useCallback(Callbacks.updateBilledHandlingDurationHandler(setFormValues, handling_amount, handling_slice), [setFormValues, handling_amount, handling_slice])
  const handleInvoiceDownload = useCallback(FrameCallbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])

  const mergedProps = {
    title: { billing_status, billing_color, stripeLink },
    row1: { formated_effective_handling_duration, load_type, duration, updateBilledHandlingDuration },
    row2: { handling, duration_amount, base, isDurationRideMode },
    row3: { prepaid, fees, option_amount, driver_package_fee },
    row4: { total, displayDownloadBillingButton, handleInvoiceDownload }
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
