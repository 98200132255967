import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid } from '@mui/material'

import List from './List'

const HistorySection = ({ disableTitle = false, ...props }) => <>
  <Grid container>
    <Grid item xs={12} hidden={disableTitle}>
      <Typography variant='h5'>{I18n.t('history.label', { count: 1 })}</Typography>
    </Grid>
    <List {...props} />
  </Grid>
</>

export default React.memo(HistorySection)
