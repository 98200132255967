
import _ from 'lodash'

class Callbacks {
  static setUserInfoHandler(setFormValues, setUserIDState) {
    return function setUserInfo(user, ignored_fields, extra_updates) {
      const full_name = _.compact([_.get(user, 'info.first_name'), _.get(user, 'info.last_name')]).join(' ') || _.get(user, 'customerinfo.name')
      const updates = _.compact(_.flatten([
        { field: 'user.first_name', value: _.get(user, 'info.first_name') },
        { field: 'user.last_name', value: _.get(user, 'info.last_name') },
        { field: 'user.name', value: _.get(user, 'customerinfo.name') },
        { field: 'user.application', value: _.get(user, 'auth.application') },
        { field: 'user.commercial_formula', value: _.get(user, 'commercial_formula') },
        { field: 'user.address', value: _.get(user, 'info.address') },
        { field: 'user.comment_to_call_taker', value: _.get(user, 'customerinfo.comment_to_call_taker') },
        { field: 'user.gsm', value: _.get(user, 'auth.gsm') },
        { field: 'user.client_number', value: _.get(user, 'customerinfo.client_number') },
        { field: 'loads.src_contact', value: full_name },
        { field: 'loads.src_phone', value: _.get(user, 'auth.gsm') },
        { field: 'payment_type', value: undefined },
        { field: 'estimation_type', value: user?.customerinfo?.special_price === 'special' ? 'special' : 'estimated' },
        { field: 'driver_comment', value: user?.customerinfo?.comment_to_driver },
        !!user && { field: 'requestedAt', meta: { focus: true } },
        extra_updates
      ]))

      const final_updates = _.filter(updates, (update) => !_.includes(ignored_fields, _.get(update, 'field')))
      setUserIDState(_.get(user, 'user_id'))
      setFormValues(final_updates)
    }
  }
  static searchClientNumberHandler(searchUser, onFieldClear, setUserInfo, application_id) {
    return async function searchClientNumber(client_number) {
      if (_.isEmpty(client_number))
        onFieldClear()
      else {
        const users = await searchUser({ customerinfo: { client_number } })
        const matched_user = _.find(users, ['auth.application', application_id])
        !!matched_user ? setUserInfo(matched_user) : setUserInfo(_.head(users), ['user.client_number'])
      }
    }
  }

  static onFieldClearHandler(parentOnFieldClear, setFormValues, loads, user) {
    return function onFieldClear() {
      parentOnFieldClear()
      setFormValues(_.compact([
        !!loads?.src_contact && { field: 'loads.src_contact', value: undefined },
        !!loads?.src_phone && { field: 'loads.src_phone', value: undefined },
        !!user?.client_number && { field: 'user.client_number', value: undefined },
        !!user?.first_name && { field: 'user.first_name', value: undefined },
        !!user?.last_name && { field: 'user.last_name', value: undefined },
        !!user?.address && { field: 'user.address', value: undefined },
        !!user?.comment_to_call_taker && { field: 'user.comment_to_call_taker', value: undefined },
      ]))
    }
  }
}

export default Callbacks
