import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        license_client_id: _.get(record, 'license_client_id'),
        license_number: _.get(record, 'license_number'),
        delivery_date: _.get(record, 'delivery_date'),
        billing_category: _.get(record, 'billing_category'),
        active: _.get(record, 'active'),
        motive: _.get(record, 'motive'),
        suspendedUntil: _.get(record, 'suspendedUntil'),
        suspendedSince: _.get(record, 'suspendedSince'),
        usual_vehicle: _.get(record, 'usual_vehicle.info.plaque'),
        status: FormatUtils.getStatus(_.pick(record, ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])),
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active'
      }
    }
  }

  static formToRecordHandler(group) {
    return function formToRecord(values, extra, meta, { suspended, ...state }) {
      return {
        group,
        license_client_id: _.get(values, 'license_client_id'),
        license_number: _.get(values, 'license_number'),
        billing_category: _.get(values, 'billing_category'),
        status: FormatUtils.parseStatus(_.get(values, 'status'), _.pick(suspended, ['suspendedUntil', 'suspendedSince', 'motive'])),
        delivery_date: _.get(values, 'delivery_date')
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        record_id: record?.companycontract_id,
        title: record?.license_client_id,
        vehicle_id: record?.usual_vehicle?.vehicle_id,
        suspended: {
          suspendedUntil: record?.suspendedUntil,
          suspendedSince: record?.suspendedSince,
          motive: record?.motive
        }
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
