import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'name_translated')} /> </TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'categoryoption.name')} /></TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'description_translated')} /> </TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'price')} /> </TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'delay')} /> </TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'priority')} /> </TableCell>
  <TableCell size={'small'}> <TextCell value={_.get(row, 'search_range')} /> </TableCell>
</>

export default React.memo(Row)
