import React from 'react'

import { Typography } from '@mui/material'

const Content = ({ option, titleProps, selectorProps, ...props }) => <>
  <Typography
    {...titleProps}
    variant={'h6'}
    sx={{ letterSpacing: 2, color: !!selectorProps?.isOptionSelected(option) && 'white' }}
  >
    {_.get(option, 'name_translated')}
  </Typography>
</>

export default React.memo(Content)
