import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ order_id, ...props }) => {
  const [order] = hooks.useModel('order', [order_id], { single: true, populate: ['job.orders'] })
  const grouped_orders = _.filter(_.get(order, 'job.orders'), elem => elem.order_id !== order_id)
  const mergedProps = {
    grouped_orders,
    order,
  }

  return <Component {...mergedProps} />
}

export default withContainer
