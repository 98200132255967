import React from 'react'

import { Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import SettingSection from './Section/Setting'
import TranslationSection from './Section/Translation'

const OptionDetails = ({ model_name, goBackPath, ...props }) => <>
  <FormHeader labelKey={`options.${model_name}`} goBackPath={goBackPath} />
  <GridStepper groupsCount={[2]} activeStep={0} orientation="vertical">
    <Step>
      <TranslationSection {...props} />
    </Step>
    <Step>
      <SettingSection />
    </Step>
  </GridStepper>
</>

export default React.memo(OptionDetails)
