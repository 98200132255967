import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [title] = hooks.useFormState('title')
  const [model_name] = hooks.useFormState('model_name')

  const mergedProps = {
    isManagingRecord,
    title,
    model_name
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
