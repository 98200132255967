import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'

import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => props => {
  const isMounted = hooks.useFormStatus('isMounted')
  const setFormValues = hooks.useFormChange()
  const [user_id] = hooks.useFormState('user_id')
  const [isB2B] = hooks.useFormState('isB2B')
  const [meeting_point] = hooks.useFormState('meeting_point.translated')
  const [driver_instruction] = hooks.useFormState('driver_instruction.translated')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [selectedCommercialPackage, companyservice_id] = hooks.useFieldValues([
    'commercial_package',
    'user.companyservice',
  ])

  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], {
    populate: ['comments.type'],
    single: true
  })
  const [commercial_package, cp_state] = hooks.useModel('commercialpackage', [selectedCommercialPackage], {
    populate: ['allowed_driver_options', 'allowed_vehicle_options'],
    single: true
  })

  const [default_vehicle_options, searchDefaultVehicleOption, vehicle_opts_state] = hooks.useModelSearch('vehicleoption', 'get', { mode: 'default' })
  const [default_driver_options, searchDefaultDriverOption, driver_opts_state] = hooks.useModelSearch('useroption', 'get', { mode: 'default' })

  const default_vehicle_opts = _.map(default_vehicle_options, 'vehicle_option_id')
  const vehicle_opts = _.uniq(_.flatten([
    default_vehicle_opts,
    _.map(commercial_package?.allowed_vehicle_options, 'vehicle_option_id')
  ]))
  const default_driver_opts = _.map(default_driver_options, 'user_option_id')
  const driver_opts = _.uniq(_.flatten([
    default_driver_opts,
    _.map(commercial_package?.allowed_driver_options, 'user_option_id')
  ]))

  const service_comment = _.find(companyservice?.comments, ['type.client_id', 'service_driver'])?.text

  useEffect(() => {
    if (!!selectedCommercialPackage) {
      const filter_opts = {
        commercial_package_id: selectedCommercialPackage,
        user_id,
        service_id: companyservice_id
      }
      searchDefaultVehicleOption(filter_opts)
      searchDefaultDriverOption(filter_opts)
    }
  }, [selectedCommercialPackage, user_id, companyservice_id])

  useEffect(() => {
    if (!!isMounted) {
      setFormValues([
        { field: 'vehicle_opts', value: default_vehicle_opts },
        { field: 'driver_opts', value: default_driver_opts }
      ])
    }
  }, [_.flatten([default_vehicle_opts, default_driver_opts]).join(',')])

  const mergedProps = {
    service_comment,
    isB2B,
    isManagingRecord,
    vehicle_opts,
    driver_opts,
    isLoading: HookUtils.getLoadingState([cp_state, vehicle_opts_state, driver_opts_state]),
    meeting_point,
    driver_instruction
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
