import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { record_id, model_name } = props
  const locale = hooks.useLocale()
  const formProps = {
    name: `${model_name}_form`,
    model_name,
    record_id,
    related_states: [],
    related_searches: [],
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(locale), [locale]),
    populate: [
      'description_trkey',
      'name_trkey'
    ],
    initialState: { isReadOnly: !!record_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
