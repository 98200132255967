class Callbacks {
  static onVehicleSelectHandler(setFormValues) {
    return function onVehicleSelect(event) {
      const record = _.head(event.target?.getParentListener()?.getRecords())
      const value = _.get(record, 'driver')
      return setFormValues([{ field: 'user_id', value: value || null }])
    }
  }
}

export default Callbacks
