import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Grid, Typography } from '@mui/material'
import { TextField, ModelMenuSelectorField } from '@front/squirtle'

const CancelPopup = (props) => {
  const { cancelation_comment_required } = props || {}

  return (
    <Box mt={2}>
      <Grid container rowSpacing={2}>
        <Grid item xs>
          <ModelMenuSelectorField
            name='reason'
            model_name='reason'
            label={I18n.t('reason.label', { count: 1 })}
            labelKeys={['name_translated']}
            allowNone={false}
            searchMode='search'
            loadOnMount
            required
            config={{ mode: 'active' }}
            selectFirst={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {I18n.t('order.cancel_justify')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cancelation_comment'
            rows={3}
            minRows={3}
            multiline
            variant='filled'
            required={cancelation_comment_required} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CancelPopup
