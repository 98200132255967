class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {
        ...state
      }
    }
  }
  static formToFilterHandler(record_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        user: record_id
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
      }
    }
  }
  static onUnlockHandler(record_id, unlockUser, openNotification) {
    return async function onUnlock(state) {
      try {

        await unlockUser({ user: record_id, device_id: _.get(state, 'row.device') })
        openNotification('User Unlocked', { variant: 'success' })
      }
      catch (err) {
        console.log("Failed to handle unlock", err)
        openNotification('Failed to unlock user', { variant: 'error' })
      }

    }
  }
  static onLogoutHandler(record_id, logoutUser, openNotification) {
    return async function onLogout(state) {
      try {
        await logoutUser({ user: record_id, refreshtoken_id: _.get(state, 'rowId') })
        openNotification('User logged out', { variant: 'success' })
      }
      catch (err) {
        console.log("Failed to handle user logout", err)
        openNotification('Failed to logout user', { variant: 'error' })
      }
    }
  }
}

export default Callbacks
