import React from 'react'
import { I18n } from '@front/volcanion'

import { ModelCardGridField, OptionSelectorField } from '@front/squirtle'
import { Typography, StepLabel, StepContent, Grid, IconButton, Icon, Box } from '@mui/material'

import { CardContent, CardHeader, CardFooter } from '@front/squirtle/components/Card/component'
import { PackageCardContent, PackageCardIcon, PackageCardPricing } from './Card'

const PackageSection = ({
  mode,
  cp_ids,
  isLoading,
  disabled_types,
  hideEstimationType,
  refreshEstimate,
  hideRefreshEstimate,
}) => <>
    <StepLabel>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h5'>{I18n.t('commercialpackage.label.short', { count: 1 })}</Typography>
        {!hideRefreshEstimate && <IconButton onClick={refreshEstimate} sx={{ p: 0, mx: 1 }}>
          <Icon sx={{ color: 'primary.main' }}>{'refresh'}</Icon>
        </IconButton>}
      </Box>
    </StepLabel>
    <StepContent>
      <Grid container spacing={1} rowSpacing={1.5}>
        <Grid item xs={12} >
          <ModelCardGridField
            name={'commercial_package'}
            model_name={'commercialpackage'}
            labelKeys={['name']}
            config={{
              ids: cp_ids,
              sortBy: 'display_index',
              sortDirection: ['DESC']
            }}
            isLoading={isLoading}
            loaderProps={{ size: 40 }}
            cardLabelProps={{ sx: { fontWeight: 500, fontSize: 'subtitle.fontSize' } }}
            cardProps={{ sx: { minWidth: 180, bgcolor: mode === 'light' ? 'gray' : 'primary.main' } }}
            required
            alwaysInclude
          >
            <CardHeader><PackageCardIcon /></CardHeader>
            <CardContent><PackageCardContent /></CardContent>
            <CardFooter><PackageCardPricing /></CardFooter>
          </ModelCardGridField>
        </Grid>
        <Grid item xs={12} hidden={hideEstimationType}>
          <OptionSelectorField
            name={'estimation_type'}
            options={[
              { label: I18n.t('price.estimated'), value: 'estimated' },
              { label: I18n.t('price.fixed'), value: 'fixed' },
              { label: I18n.t('price.special'), value: 'special' }
            ]}
            disabledOptions={disabled_types}
            required={!hideEstimationType}
            alwaysInclude
          />
        </Grid>
      </Grid>

    </StepContent>
  </>

export default React.memo(PackageSection)
