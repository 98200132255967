import React from 'react'
import { I18n } from '@front/volcanion'
import { ModelMenuSelectorField, TextField } from '@front/squirtle'
import { Grid, Typography } from '@mui/material'

const SendCommentForm = ({ popupTitle, ...props }) => <>
  <Grid container rowSpacing={1.5} justifyContent='center'>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: 'h5.fontSize', letterSpacing: 2, color: 'primary.main' }}>{popupTitle} </Typography>
    </Grid>
    <Grid item xs={6}>
      <TextField label="Titre" name={'title'} disabled={true} />
    </Grid>
    <Grid item xs={8}>
      <ModelMenuSelectorField
        label={I18n.t('comment.type')}
        name={'type'}
        model_name={'commenttype'}
        labelKeys={['label']}
        searchMode={'search'}
        required
        loadOnMount
        config={{
          initial_filter: { category: 'JFPNk9Qxj' },
          sort: ['display_index ASC'],
          mutator: elem => ({ ...elem, label: _.upperFirst(_.get(elem, `name_translated`)) })
        }}
      />
    </Grid>
    <Grid item xs={10}>
      <TextField
        label={`Message `}
        name={'text'}
        multiline
        minRows={2}
      />
    </Grid>
  </Grid>
</>

export default SendCommentForm
