import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({
  PACKAGE_ENABLED,
  onFieldClear: parentOnFieldClear,
  onApplicationChange: parentOnApplicationChange,
  searchGSM: parentSearchGsm,
  searchUser,
  ...props
}) => {
  const setFormValues = hooks.useFormChange()
  const [user_id, setUserIDState] = hooks.useFormState('user_id')
  const [isB2B] = hooks.useFormState('isB2B')
  const [
    load_type,
    companyservice_id,
    application_id,
    commercial_formula,
    loads,
    user
  ] = hooks.useFieldValues([
    'load_type',
    'user.companyservice',
    'user.application',
    'user.commercial_formula',
    'loads',
    'user'
  ])

  const [application] = hooks.useModel('application', [application_id], { single: true })
  const special_load_type = _.toLower(_.get(application, 'name')) === 'collecto' ? _.toLower(_.get(application, 'name')) : load_type

  const setUserInfo = useCallback(Callbacks.setUserInfoHandler(setFormValues, setUserIDState), [setFormValues, setUserIDState])
  const onFieldClear = useCallback(Callbacks.onFieldClearHandler(parentOnFieldClear, setFormValues, loads, user), [parentOnFieldClear, setFormValues, loads, user])
  const onApplicationChange = useCallback(app_id => parentOnApplicationChange(app_id, setUserInfo), [setUserInfo, parentOnApplicationChange])
  const searchGSM = useCallback(gsm => parentSearchGsm(gsm, setUserInfo, onFieldClear), [setUserInfo, parentSearchGsm, onFieldClear])
  const searchClientNumber = useCallback(Callbacks.searchClientNumberHandler(searchUser, onFieldClear, setUserInfo, application_id), [searchUser, onFieldClear, setUserInfo, application_id])
  const handleChangeRidemode = useCallback(() => setFormValues([{ field: 'ridemode_duration', value: undefined }]))

  const mergedProps = {
    handleChangeRidemode,
    onApplicationChange,
    isB2B,
    searchClientNumber,
    searchGSM,
    PACKAGE_ENABLED: special_load_type !== 'collecto' && PACKAGE_ENABLED,
    load_type,
    user: user_id,
    commercial_formula,
    application: application_id,
    service: companyservice_id
  }

  return (
    <Component  {...props} {...mergedProps} />
  )
}
export default withContainer
