import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callback'

const withContainer = Component => ({ order_id, children }) => {
  const setFormValues = hooks.useFormChange()
  const [vehicle_ids] = hooks.useModelSearch('vehicle', 'getClosest', {
    forced_filter: { order_id },
    initial_filter: {},
    watchers: [order_id],
    validate_filter: useCallback(() => !!order_id, [order_id]),
    mutator: 'vehicle_id'
  })
  const [user_id] = hooks.useFieldValues(['user_id'])

  const mergedProps = {
    order_id,
    vehicle_ids,
    getSourceStyle: useCallback((instance, records) => ({ icon: _.head(records)?.commercial_package?.monitoring_icon || 'airline_seat_recline_extra' }), []),
    getVehicleStyle: useCallback((instance, records) => ({ color: _.head(records)?.driver === user_id ? 'blue' : 'green' }), [user_id]),
    onVehicleSelect: useCallback(Callbacks.onVehicleSelectHandler(setFormValues), [setFormValues]),
    children
  }

  return <Component {...mergedProps} />
}

export default withContainer
