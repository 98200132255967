import React from 'react'
import { MapFactory } from '@front/squirtle'
import { Box } from '@mui/material'

const ManualDispatch = ({ order_id, vehicle_ids, getSourceStyle, getVehicleStyle, onVehicleSelect, children }) => <>
  <Box sx={{ boxShadow: 6, borderRadius: 6 }}>
    <MapFactory.MapContainer mapStyle={{ height: 700 }} mapId='manual_dispatch'>
      <MapFactory.SuperLayer fitToBounds followBounds boundOptions={{ animate: true }}>
        <MapFactory.Marker
          config={{
            model_name: 'order',
            ids: [order_id],
            populate: ['source', 'commercial_package'],
            coordinates_path: 'source.coordinates'
          }}
          getStyles={getSourceStyle}
          styles={{ color: 'green' }}
        />
        {_.map(vehicle_ids, (vehicle_id) => (
          <MapFactory.Marker
            key={vehicle_id}
            config={{
              model_name: 'vehicle',
              ids: [vehicle_id],
              populate: ['current_location'],
              coordinates_path: 'current_location.coordinates'
            }}
            getStyles={getVehicleStyle}
            styles={{ icon: 'local_taxi' }}
            eventHandlers={{
              click: onVehicleSelect
            }}
          />
        ))}
      </MapFactory.SuperLayer>
      {children}
    </MapFactory.MapContainer>
  </Box>
</>
export default React.memo(ManualDispatch)
