import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField } from '@front/squirtle'

const Title = ({ title, ...props }) =>
  <Grid item xs={12}>
    <Typography sx={{ color: 'primary.main', fontSize: 'h5.fontSize', letterSpacing: 1.5 }}>
      {title}
    </Typography>
  </Grid>

const Row1 = ({ type, ...rest }) => {
  return (
    <Grid container item xs={10} justifyContent={'center'} spacing={1} >
      <Grid item xs>
        <ModelAutocompleteField
          name={type === 'driver' ? 'user_option_id' : 'vehicle_option_id'}
          label={I18n.t('name.label', { count: 1 })}
          model_name={type === 'driver' ? 'useroption' : 'vehicleoption'}
          searchMode='search'
          autocompleteMutator={q => ({
            name_trkey: { fr: { contains: q } }
          })}
          labelKeys={['name_trkey.fr']}
          config={{
            populate: ['name_trkey']
          }}
          debounceTime={400}
          minQueryLength={1}
          clearOnEmpty
        />
      </Grid>
      <Grid item xs>
        <ModelAutocompleteField
          name='categoryoption'
          label={I18n.t('category.label', { count: 1 })}
          model_name='categoryoption'
          autocompleteKey={'name'}
          labelKeys={['name']}
          searchQuery={'startsWith'}
          searchMode={'search'}
          loadOnMount
          config={{
            forced_filter: { type },
            validate_filter: useCallback(() => !!type, [type]),
            watchers: [type],
          }}
          multiple
        />
      </Grid>
    </Grid>
  )
}

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title {...props} />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
