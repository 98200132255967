import React from 'react'
import { I18n } from '@front/volcanion'
import { useTheme } from '@mui/styles'

import { Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import { ModelMenuSelectorField, MenuSelectorField, TextField, SingleOptionSelectorField } from '@front/squirtle'
import { isPositive } from '@front/squirtle/utils/validators'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name='price'
        label={I18n.t('price.label')}
        type={'number'}
        validate={isPositive}
        InputProps={{
          endAdornment: I18n.t('fiat.eur.sign'),
          inputProps: { style: { textAlign: 'right' } }
        }}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='display_index'
        label={I18n.t('display.index')}
        type='number'
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('priority.label', { count: 1 })}
        name={'priority'}
        options={[
          { label: I18n.t('priority.0'), value: 0 },
          { label: I18n.t('priority.1'), value: 1 },
          { label: I18n.t('priority.2'), value: 2 },
          { label: I18n.t('priority.3'), value: 3 }
        ]}
        selectFirst
        required
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='categoryoption'
        label={I18n.t('category.label', { count: 1 })}
        model_name='categoryoption'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        selectFirst={false}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'delay'}
        label={I18n.t('date.delay_start')}
        type='number'
        emptyValue='-'
        variant='standard'
        size='small'
        required
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography> {I18n.t('minute.label.short')} </Typography>
        }}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'search_range'}
        label={I18n.t('options.search_range')}
        type='number'
        emptyValue='-'
        variant='standard'
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography > {I18n.t('prefix.km')} </Typography>
        }}
      />
    </Grid>
  </Grid>


const Row2 = props => {
  const theme = useTheme()
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <SingleOptionSelectorField
          name='customer_display'
          optionLabel={I18n.t('display.customer', { count: 1 })}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } }
          })}
        />
      </Grid>
      <Grid item xs={2}>
        <SingleOptionSelectorField
          name='driver_display'
          optionLabel={I18n.t('display.driver', { count: 1 })}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } }
          })}
        />
      </Grid>
    </Grid>
  )
}

const SettingSection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('setting.label', { count: 2 })}</Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }} >
      <Grid container rowSpacing={2} >
        <Row1 />
        <Row2 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(SettingSection)
