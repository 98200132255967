import React from 'react'

import { SuspensionPopupForm } from '@abra/elements/Suspensions'
import { FormDialog, DialogContent, ModelAutocompleteField } from '@front/squirtle'
import { I18n } from '@front/volcanion'

const Popup = ({ record_id, onSubmit, hasRelayVehicle, group_id }) => (
  <>
    <SuspensionPopupForm record_id={record_id} model_name={"license"} />
    <FormDialog
      name={'relay_popup'}
      formProps={{
        initialValues: {},
        onSubmit
      }}
    >
      <DialogContent>
        <ModelAutocompleteField
          name='vehicle_id'
          model_name='vehicle'
          autocompleteKey={'info.plaque'}
          searchQuery={'startsWith'}
          label={I18n.t('vehicle.label', { count: 1 })}
          _label={{ _text: { fontSize: 16, color: 'primary.600' }, style: { padding: 2 } }}
          labelKeys={['info.plaque']}
          searchMode='search'
          loadOnMount
          required
          selectFirst={false}
          config={{
            forced_filter: {
              active: true,
              parent_license: null,
              group: hasRelayVehicle ? group_id : { type: 'relay' }
            },
            populate: ['info']
          }}
        />
      </DialogContent>
    </FormDialog>
  </>
)

export default React.memo(Popup)
