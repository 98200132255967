import React from 'react'
import { I18n } from '@front/volcanion'

import moment from 'moment'

const withContainer = Component => ({ requestedAt, schedule_type, ...rest }) => {
  const order_type = !!schedule_type && I18n.t(`order.${schedule_type}.longLabel`, { count: 1 })
  const requestedAtFormatted = moment(requestedAt).format(schedule_type === 'immediate' ? 'HH[h]mm[min]' : '[le] DD/MM/YYYY HH[h]mm[min]')

  const mergedProps = {
    order_type,
    requestedAtFormatted
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer