class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler(license) {
    return function formToFilter(values, extra, meta, state) {
      return {
        license,
        ...values
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        quantity: 1
      }
    }
  }

  static formToRecordHandler(license) {
    return function formToRecord(values, extra, meta) {
      return {
        quantity: _.get(values, 'quantity'),
        equipment: _.get(values, 'equipment'),
        license
      }
    }
  }
}

export default Callbacks
