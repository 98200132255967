import UserUtils from '@front/volcanion/utils/user'
class Callbacks {
  static setUserInfoHandler(setFormValues, setUserIDState, setAuthenticationTypeState) {
    return function setUserInfo(user, ignored_fields, extra_updates) {
      const user_companies = user?.user_companies

      const direct_user_companies = _.compact(_.map(user_companies, c_user => {
        if (!c_user.active) return false
        const companyuser_services = _.filter(c_user?.companyuser_services, ['companyservice.active', true])

        const direct_services = _.filter(
          _.filter(companyuser_services, ['companyservice.contract.authentication_type', 'direct'])
          , ['companyservice.active', true]
        )

        if (_.size(direct_services) === 1) {
          const cu_service = _.head(direct_services)
          const authentication_type = cu_service?.companyservice?.contract?.authentication_type
          return {
            company: cu_service?.companyservice?.contract?.company,
            companycontract: cu_service?.companyservice?.contract?.companycontract_id,
            companyuser: cu_service?.companyuser?.self_booking ? cu_service?.companyuser?.companyuser_id : undefined,
            companyservice: cu_service?.companyservice?.companyservice_id,
            password: cu_service?.companyservice?.password,
            commercial_formula: cu_service?.companyservice?.contract?.formula,
            address: cu_service?.companyservice?.contract?.address,
            authentication_type
          }
        } else return false
      }))

      const selectedCompany = _.size(direct_user_companies) > 1 ? undefined : _.head(direct_user_companies)

      const updates = _.compact(_.flatten([
        { field: 'user.gsm', value: user?.auth?.gsm },
        { field: 'user.application', value: user?.auth?.application },
        { field: 'user.commercial_formula', value: !!selectedCompany ? selectedCompany?.commercial_formula : user?.commercial_formula },
        { field: 'user.password', value: selectedCompany?.password },
        { field: 'user.address', value: selectedCompany?.address },
        { field: 'user.company', value: selectedCompany?.company },
        { field: 'user.companycontract', value: selectedCompany?.companycontract, meta: { focus: !selectedCompany } },
        { field: 'requestedAt', meta: { focus: !!selectedCompany } },
        { field: 'user.companyservice', value: selectedCompany?.companyservice },
        { field: 'user.companyuser', value: selectedCompany?.companyuser },
        { field: 'driver_comment', value: user?.customerinfo?.comment_to_driver },
        { field: 'payment_type', value: undefined },
        extra_updates
      ]))

      const final_updates = _.filter(updates, (update) => !_.includes(ignored_fields, _.get(update, 'field')))

      setAuthenticationTypeState(selectedCompany?.authentication_type)
      setUserIDState(user?.user_id)
      setFormValues(final_updates)
    }
  }
  static onSearchCompanycontractHandler(searchCompanycontract, onFieldClear, setFormValues, setAuthenticationTypeState) {
    return async function onSearchCompanycontract(companycontract_id) {
      if (_.isEmpty(companycontract_id))
        setFormValues([
          { field: 'user.companyuser', value: null },
          { field: 'user.companyservice', value: undefined },
          { field: 'user.companycontract', value: undefined },
          { field: 'user.company', value: undefined },
          { field: 'user.password', value: undefined },
          { field: 'payment_type', value: undefined },
        ])
      else {
        const companycontracts = await searchCompanycontract({ companycontract_id })
        const companycontract = _.head(companycontracts)
        if (!companycontract.active) return
        const updates = _.compact(_.flatten([
          { field: 'user.address', value: _.get(companycontract, 'contact.address') },
          { field: 'user.commercial_formula', value: _.get(companycontract, 'formula') },
          { field: 'user.company', value: _.get(companycontract, 'company') },
          { field: 'payment_type', value: undefined },
        ]))

        setAuthenticationTypeState(_.get(companycontract, 'authentication_type'))
        setFormValues(updates)

      }
    }
  }
  static onSearchCompanyserviceHandler(searchCompanyservice, searchCallerCompanyuser, setFormValues, user_id) {
    return async function onSearchCompanyservice(companyservice_id) {
      if (_.isEmpty(companyservice_id))
        setFormValues([
          { field: 'user.companyuser', value: null },
          { field: 'user.companyservice', value: undefined },
          { field: 'user.password', value: undefined },
        ])
      else {
        searchCompanyservice({ companyservice_id })

        if (!!user_id) {
          const companyusers = await searchCallerCompanyuser({ services: [companyservice_id], user: user_id })
          const companyuser = _.head(companyusers)
          const full_name = UserUtils.getFormatedName({ ...companyuser?.contact, designation: companyuser?.user?.customerinfo?.name })

          if (!!companyuser && !!_.get(companyuser, 'self_booking')) {
            setFormValues([
              { field: 'loads.src_contact', value: full_name },
              { field: 'user.companyuser', value: _.get(companyuser, 'companyuser_id') },
              { field: 'user.selected', value: _.get(companyuser, 'user.user_id') },
              { field: 'loads.src_phone', value: _.get(companyuser, 'user.auth.gsm') },
              { field: 'payment_type', value: undefined },
            ])
          }
        } else {
          setFormValues([{ field: 'payment_type', value: undefined }])
        }
      }
    }
  }
  static onSearchCompanyuserHandler(searchCompanyuser, setCompanyuser, setFormValues, load_type) {
    return async function onSearchCompanyuser(companyuser_id) {
      if (_.isEmpty(companyuser_id)) return

      const companyusers = await searchCompanyuser({ companyuser_id })
      const companyuser = _.head(companyusers)
      const hasOnlyGuest = !_.get(companyuser, 'self_booking') && !!_.get(companyuser, 'guest_booking')
      const full_name = UserUtils.getFormatedName({ ...companyuser?.contact, designation: companyuser?.user?.customerinfo?.name })

      setFormValues([
        { field: 'user.selected', value: _.get(companyuser, 'user.user_id') },
        { field: 'user.companyuser', value: _.get(companyuser, 'companyuser_id') },
        { field: 'guest', value: !!companyuser_id ? !!hasOnlyGuest : false },
        { field: 'loads.src_contact', value: full_name },
        { field: 'loads.src_phone', value: !!companyuser_id ? _.get(companyuser, 'user.auth.gsm') : null }
      ])

      setCompanyuser(_.get(companyuser, 'companyuser_id'))
    }
  }
  static getCompanyuserFilterHandler() {
    return function getCompanyuserFilter(companyuser_id, company, companycontract, booking_level, self_booking, companyservice_id, caller_companyuser) {
      const exclude_user = !!caller_companyuser && !self_booking ? { companyuser_id: { '!': caller_companyuser?.companyuser_id } } : {}
      if (!booking_level && !!self_booking) return _.merge({}, { companyuser_id }, exclude_user)
      else if (booking_level === 'company') return _.merge({}, { company }, exclude_user)
      else if (booking_level === 'contract' && !!self_booking) return _.merge({}, { services: { contract: companycontract } }, exclude_user)
      else if (booking_level === 'contract' && !self_booking) return _.merge({}, { services: { contract: companycontract } }, exclude_user)
      else return _.merge({}, { services: { companyservice_id } }, exclude_user)
    }
  }

  static getCompanyuserValidateHandler(booking_level, self_booking, companyservice_id) {
    return function getCompanyuserValidate() {
      return !!booking_level || !!self_booking || !!companyservice_id
    }
  }

  static onFieldClearHandler(parentOnFieldClear, setFormValues, clearCompanyservices, clearCompanycontracts) {
    return function onFieldClear() {
      parentOnFieldClear()
    }
  }
}

export default Callbacks
