import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('driver.login.createdAt')} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('driver.login.lastSeenAt')} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('driver.login.platform_os')} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('driver.login.platform_type')} />
  </TableCell>
  <TableCell align={'center'}>
    <TextHeader label={I18n.t('driver.login.app_version')} />
  </TableCell>
</>

export default React.memo(Header)
