const buttons = [
  [
    { key: 1, hotkey: 'Escape', action: 'cancel_action' },
    { key: 2, hotkey: ['Ctrl', 'Enter'], action: 'create_order' },
    { key: 3, hotkey: ['Ctrl', '²'], action: 'quotation' },
    { key: 4, hotkey: ['Ctrl', 'ArrowRight'], action: 'next_order' }
  ],
  [
    { key: 5, hotkey: 'F1', action: 'help' },
    { key: 7, hotkey: 'F2', action: 'open_comment_popup' },
    { key: 8, hotkey: ['Alt', 'F2'], action: 'operator_orders' },
    { key: 10, hotkey: 'F3', action: 'open_order_detail_page' },
    { key: 11, hotkey: ['Alt', 'F3'], action: 'open_order_detail_page_with_client_id' }
  ],
  [
    { key: 12, hotkey: 'F5', action: 'display_map' },
    { key: 13, hotkey: ['Alt', 'F5'], action: 'open_order_search_page' },
    { key: 14, hotkey: 'F6', action: 'manual_dispatch' },
    { key: 15, hotkey: ['Ctrl', 'F6'], action: 'redispatch' },
    { key: 16, hotkey: ['Alt', 'F6'], action: 'dispatch_in_progress' },
    { key: 17, hotkey: 'F7', action: 'call_driver' },
    { key: 18, hotkey: 'F8', action: 'modification' },
  ],
  [
    { key: 19, hotkey: 'F9', action: 'call_customer' },
    { key: 20, hotkey: ['Alt', 'F9'], action: 'open_order_search_with_all_orders_of_driver' },
    { key: 21, hotkey: ['Alt', 'F10'], action: 'open_driver_detail_page' },
    { key: 22, hotkey: 'F11', action: 'full_screen' },
    { key: 23, hotkey: ['Alt', 'F11'], action: 'open_message_search_page' }
  ],
  [
    { key: 24, hotkey: 'F12', action: 'approve' },
    { key: 25, hotkey: ['Ctrl', 'F12'], action: 'refuse' },
    { key: 26, hotkey: ['Alt', 'F12'], action: 'open_incident_page' },
    { key: 27, hotkey: ['Ctrl', 'F8'], action: 'cancel' },
    { key: 28, hotkey: ['Alt', 'Enter'], action: 'switchB2B' }
  ]
]

export default buttons
