import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [modifyVehicle] = hooks.useModelFunction('vehicle', 'modify')
  const [modifyLicense] = hooks.useModelFunction('license', 'modify')
  const [license] = hooks.useModel('license', [record_id], { populate: ['usual_vehicle.group'], single: true })
  const onSubmit = useCallback(async ({ vehicle_id }) => {
    await modifyLicense({ filter: { license_id: record_id }, updates: { usual_vehicle: null } })
    await modifyVehicle({ filter: { vehicle_id }, updates: { parent_license: record_id } })
  }, [record_id, modifyLicense, modifyVehicle])

  const mergedProps = {
    record_id,
    onSubmit,
    hasRelayVehicle: license?.usual_vehicle?.group?.type === 'relay',
    group_id: license?.group
  }

  return <Component {...mergedProps} />
}

export default withContainer
