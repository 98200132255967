import React from 'react'
import { hooks } from '@front/volcanion'

import { ModelMenuSelectorField, TextComponent, TextField } from '@front/squirtle'
import { TableCell } from '@mui/material'

const Row = ({ row }) => {
  const [equipment] = hooks.useModel('equipment', [_.get(row, 'equipment')], { single: true })
  return <>
    <TableCell size={'small'}>
      <ModelMenuSelectorField
        name={'equipment'}
        model_name='equipment'
        labelKeys={['name_translated']}
        searchMode='search'
        allowNone={false}
        loadOnFocus
      />
    </TableCell>
    <TableCell sx={{ minWidth: 350 }}> <TextComponent value={_.get(equipment, 'description_translated')} disabled /> </TableCell>
    <TableCell size={'small'}>
      <TextComponent
        value={_.get(equipment, 'price')}
        InputProps={{
          inputProps: { style: { textAlign: 'center' } },
          endAdornment: _.get(equipment, 'currency')
        }}
        disabled
      />
    </TableCell>
    <TableCell size={'small'}> <TextField name='quantity' type='number' inputProps={{ style: { textAlign: 'center' } }} /></TableCell>
    <TableCell size={'small'}>
      <TextComponent
        name={'equipment.price'}
        value={!!_.get(row, 'equipment') ? _.toNumber(_.get(row, 'quantity')) * _.get(equipment, 'price') : undefined}
        InputProps={{
          inputProps: { style: { textAlign: 'center' } },
          endAdornment: _.get(equipment, 'currency')
        }}
        disabled
      />
    </TableCell>
  </>
}


export default React.memo(Row)
