import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { usePhoneCall } from '@abra/hooks/ivr'

const withContainer = Component => ({ order_id }) => {
  const onPhoneCall = usePhoneCall()
  const EXTERNAL_PHONE_SERVICE = hooks.useRelayConstant('EXTERNAL_PHONE_SERVICE')
  const [user_id] = hooks.useFieldValues(['user_id'])
  const [user] = hooks.useModel('user', [user_id], { single: true, populate: ['auth'], })
  const canCallDriver = !!user && !!EXTERNAL_PHONE_SERVICE
  const callDriver = useCallback(() => onPhoneCall(user?.auth?.gsm), [onPhoneCall, user?.auth?.gsm])

  const mergedProps = {
    order_id,
    user_id,
    canCallDriver,
    callDriver,
  }

  return <Component {...mergedProps} />
}

export default withContainer
