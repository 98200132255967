import ValidationUtils from '@front/volcanion/utils/validation'

class Callbacks {
  static onLoadTypeChangeHandler(user, setFormValues, payment_type) {
    return function onLoadTypeChange(load_type) {
      if (!!payment_type)
        setFormValues([{ field: 'payment_type', value: undefined }])
      if (!!user) {
        const full_name = _.compact([_.get(user, 'info.first_name'), _.get(user, 'info.last_name')]).join(' ') || _.get(user, 'customerinfo.name')
        setFormValues([
          { field: 'loads.src_contact', value: load_type === 'user' ? full_name : undefined },
        ])
      }
    }
  }
  static onApplicationChangeHandler(setFormValues, applications, users, user_id) {
    return function onApplicationChange(application_id, setUserInfo) {
      const application = _.find(applications, ['application_id', application_id])
      const user = _.find(users, ['auth.application', application_id])
      const extra_updates = _.toLower(_.get(application, 'name')) === 'collecto' ? [{ field: 'load_type', value: 'user' }] : []
      if (!!user || !!user_id)
        setUserInfo(user, !user && !!user_id ? ['user.gsm'] : [], extra_updates)
      else
        setFormValues(_.compact(_.flatten([
          { field: 'user.commercial_formula', value: _.get(application, 'default_commercialformula') },
          extra_updates
        ])))
    }
  }
  static onFieldClearHandler(clearUsers, setUserState) {
    return function onFieldClear() {
      clearUsers()
      setUserState(undefined)
    }
  }
  static searchGSMHandler(searchUser, application, user_id) {
    return async function searchGSM(gsm, setUserInfo, onFieldClear) {
      if (_.isEmpty(gsm))
        onFieldClear()
      else {
        const users = await searchUser({ auth: { gsm } })
        const matched_user = _.find(users, ['auth.application', application])
        if (!!matched_user) setUserInfo(matched_user)
        else if (!!user_id) setUserInfo(null, ['user.gsm'])
        else onFieldClear()
      }
    }
  }
  static normalizePhoneHandler(CODE_INTERNATIONAL) {
    return function normalizePhone(value) {
      return ValidationUtils.parsePhone(value, CODE_INTERNATIONAL)
    }
  }
  static onTypeChangeHandler(B2B_ENABLED, isB2B, setIsB2B, setFormValues) {
    return function onTypeChange(value) {
      setFormValues([
        { field: 'loads.src_contact', value: null },
        { field: 'loads.src_phone', value: null },
        { field: 'user.client_number', value: null },
        { field: 'user.first_name', value: null },
        { field: 'user.last_name', value: null },
        { field: 'user.commercial_formula', value: null },
        { field: 'user.companycontract', value: undefined },
        { field: 'user.companyservice', value: undefined }
      ])
      !!B2B_ENABLED && setIsB2B(!isB2B)
    }
  }
}

export default Callbacks
