import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [group_id] = hooks.useFormState('group_id')
  const [vehicle_id] = hooks.useFormState('vehicle_id')
  const [status, usual_vehicle] = hooks.useFieldValues(['status', 'usual_vehicle'])
  const [, { openDialog }] = hooks.useDialogs()
  const [, setSuspendedState] = hooks.useFormState('suspended')

  const verifyClientId = useCallback(hooks.useRecordExist('license', 'license_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])

  const color = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    record_id,
    isManagingRecord,
    color,
    group_id,
    usual_vehicle,
    vehicle_id,
    statusChange,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
