import React from 'react'
import { TextField } from '@front/squirtle'
import { Grid, Typography } from '@mui/material'

const SendMessageDriverForm = ({ popupTitle, ...props }) => <>
  <Grid container rowSpacing={1.5} justifyContent='center'>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: 'h5.fontSize', letterSpacing: 2, color: 'primary.main' }}>{popupTitle} </Typography>
    </Grid>
    <Grid item xs={6}>
      <TextField label="Titre" name={'title'} disabled={true} />
    </Grid>
    <Grid item xs={10}>
      <TextField
        label={`Message `}
        name={'text'}
        multiline
        minRows={2}
      />
    </Grid>
  </Grid>
</>


export default SendMessageDriverForm
