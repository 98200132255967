import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, TableRowActions, TableActions, ConfirmationDialog, TableDialogAction, FormContent } from '@front/squirtle'
import { Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import _ from 'lodash'

const Title = props =>
  <Grid container item xs={12}>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('info.login')} </Typography>
    </StepLabel >
  </Grid>

const UnlockAction = props => {
  if (!_.get(props, 'row.device') && _.get(props, 'action.type') !== 'global')
    return null
  return <TableDialogAction {...props} label={'Unlock'} name={"driver_unlock"} getDialogExtra={() => ({ title: I18n.t('driver.login.unlock.title'), description: I18n.t('driver.login.unlock.description') })} />
}

const LoginSection = ({ record_id, navigate, isManagingRecord, unlockUser, logoutUser, openNotification, tableProps, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const onUnlock = useCallback(Callbacks.onUnlockHandler(record_id, unlockUser, openNotification), [record_id, unlockUser, openNotification])
  const onLogout = useCallback(Callbacks.onLogoutHandler(record_id, logoutUser, openNotification), [record_id, logoutUser, openNotification])
  return <Box hidden={!isManagingRecord}>
    <Grid container rowSpacing={3}>
      <Title />
      <Grid item xs={12}>
        <StepContent>
          <ConfirmationDialog name={'driver_logout'} onConfirm={onLogout} />
          <ConfirmationDialog name={'driver_unlock'} onConfirm={onUnlock} />
          <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
            <SearchFrame
              sort={['lastSeenAt DESC']}
              model_name={'refreshtoken'}
              allowCreate={false}
              createPlacement={'bottom'}
            >
              <SearchForm
                filterToForm={filterToForm}
                formToFilter={formToFilter}
                filterToState={filterToState}
                submitOnMount
                disableButton
              >
                <FormContent><Form /></FormContent>
              </SearchForm>
              <SearchTable
                tableProps={{
                  onRowClick: null,
                  enableToolbar: true,
                  enablePagination: true,
                  enableRowActions: true,
                  enableRowSelection: false,
                  enableGlobalActions: true,
                  enableGlobalActionsPlacement: 'top',
                  disableCancelRowLabel: true,
                  disableSaveRowLabel: true,
                  ...tableProps
                }}
              >
                <TableHeader><Header /></TableHeader>
                <TableRow><Row /></TableRow>
                <TableActions>
                  <UnlockAction />
                  <TableDialogAction label={'Logout All'} name={"driver_logout"} getDialogExtra={() => ({ title: I18n.t('driver.login.logout.title'), description: I18n.t('driver.login.logout.description') })} />
                </TableActions>
                <TableRowActions>
                  <UnlockAction />
                  <TableDialogAction label={'Logout'} name={"driver_logout"} getDialogExtra={() => ({ title: I18n.t('driver.login.logout.title'), description: I18n.t('driver.login.logout.description') })} />
                </TableRowActions>
              </SearchTable>
            </SearchFrame >
          </Box >
        </StepContent>
      </Grid>
    </Grid>
  </Box>
}

export default React.memo(LoginSection)
